'use strict';
var baseQV = require('base/product/quickView');

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickViewModal" role="dialog" tabindex="-1" aria-hidden="true" aria-labelledby="ModalTitle">'
        + '<div class="modal-dialog quick-view-dialog"  role="document">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close">'
        + '        &times;'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getWaitListModalHtmlElement() {
    if ($('#waitListModal').length !== 0) {
        $('#waitListModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade wait-list-modal" id="waitListModal" role="dialog" aria-hidden="true" tabindex="-1" >'
        + '<div class="modal-dialog modal-dialog-centered" role="document">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="wait-list-close pull-right" data-dismiss="modal">'
        + '        &times;'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseWaitListHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-waitlist');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {string} target - element that was clicked to init this function
 */
function fillModalElement(selectedValueUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            var $qvModal = $('#quickViewModal');

            $('.modal-body').empty();
            $('.modal-body').html(parsedHtml.body);

            $qvModal
                .modal({
                    show: true
                })
                .css({
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                })
                .on('shown.bs.modal', function () {
                    $(this).find('button').eq(0).focus();
                })
                .on('hidden.bs.modal', function () {
                    $('.js-qv-item').focus().removeClass('js-qv-item');
                });

            $('.modal-open').css({
                overflowY: 'scroll',
                padding: 0
            });

            $('.modal-backdrop').click(function (ev) {
                if (ev.target !== $('.quick-view-dialog')) {
                    $('#quickViewModal').modal('hide');
                }
            });

            if (typeof affirm !== 'undefined') {  //eslint-disable-line
                affirm.ui.refresh(); // eslint-disable-line
            }
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} pid - product id
 * @param {string} productName - product name
 * @param {string} productURL - product storefront link
 */
function fillWaitListModalElement(pid, productName, productURL) {
    $('.modal-body').spinner().start();
    var parsedHtml = parseWaitListHtml($('.modal-group').html());

    $('.modal-body').empty();
    $('.modal-body').html(parsedHtml.body);

    $('#waitListModal').modal({
        show: true
    });

    $('.modal-open').css({
        overflowY: 'scroll',
        padding: 0
    });

    $('.modal-backdrop').click(function (ev) {
        if (ev.target !== $('.wait-list-dialog')) {
            $('#waitListModal').modal('hide');
        }
    });

    var $emwaSelector = $('.email-when-available-overlay');
    $emwaSelector.find('.emwa-form-div').show();
    $emwaSelector.find('.emea-opt-in-success').hide();
    var $emwa = $('.emwa-form-div');
    $emwa.find('span.product-title').text(productName);
    $emwa.find('#productID').val(pid);
    $emwa.find('#productName').val(productName);
    $emwa.find('#ProductLink').val(productURL);
    $('.modal-backdrop').addClass('show-shadow');

    $.spinner().stop();
}

/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {string} quantity product quantity
 */
function addToCartService(productObject, quantity) {
    var dataLayer = window.dataLayer;
    var quantityObj = {
        quantity: quantity
    };

    var obj = {
        event: 'addToCart',
        ecommerce: {
            add: {
                products: []
            }
        }
    };

    obj.ecommerce.add.products.push($.extend(productObject, quantityObj));
    obj.visitor = $.extend({}, dataLayer[0].visitor);
    dataLayer.push(obj);
}

/**
 * Add service product in the cart when user service is selected
 * @param {Object} $serviceSubscriptionCard - serviceSubscriptionCard class object
 * @param {jQuery} $selectedElement - the selected element
 */
function serviceSubscriptionSelect($serviceSubscriptionCard, $selectedElement) {
    var addToCartUrl = $serviceSubscriptionCard.find('.add-to-cart-url').val();
    var serviceCheckIcon = $serviceSubscriptionCard.find('.serviceCheckIcon').val();
    var serviceAddedMessage = $serviceSubscriptionCard.find('.serviceAddedMessage').val();
    var serviceName = $selectedElement.val();
    var variantID = $selectedElement.data('pid');
    var gtmData = $selectedElement.data('gtmdata');
    var pliUUID = $selectedElement.data('uuid');
    var upsellUUID = $selectedElement.data('upsell-uuid');
    var $serviceBody = $('.service-body_' + upsellUUID);
    var htmlToAppend = '';
    var form = {
        pid: variantID,
        quantity: '1',
        hardwarePLI: pliUUID,
        options: []
    };

    $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: form,
        success: function (addToCartdata) {
            var etmc = window._etmc || null;    // eslint-disable-line no-underscore-dangle
            if (!addToCartdata.error) {
                htmlToAppend += '<p><img src=\'' + serviceCheckIcon + '\'>'
                                 + serviceAddedMessage.replace('service_name', '<b>' + serviceName + '</b>') + '</p></div>';
                $serviceBody.empty().append(htmlToAppend);
                addToCartService(gtmData, 1); // eslint-disable-line no-undef
                if (etmc && addToCartdata && addToCartdata.etmcTrackCartObject) {
                    etmc.push(['trackCart', addToCartdata.etmcTrackCartObject]);
                }
            }
            $('.serviceSubscriptionCard').spinner().stop();
        },
        error: function () {
            $('.serviceSubscriptionCard').spinner().stop();
        }
    });
}


// Using $.extend we can pass in the original product/base file and then anything provided will either be overwritten or added.
module.exports = $.extend({}, baseQV, {
    showQuickview: function () {
        $('body').on('click', '.js-quickview', function (e) {
            e.preventDefault();
            $(this).addClass('js-qv-item');
            var selectedValueUrl = $(this).closest('a.quickview').attr('href');
            $(e.target).trigger('quickview:show');
            getModalHtmlElement();
            fillModalElement(selectedValueUrl, e.target);
        });
    },
    addToWaitList: function () {
        $('body').on('click', '.add-to-wait-list', function (e) {
            e.preventDefault();
            var pid = $(this).data('pid');
            var productName = $(this).data('name');
            var productURL = $(this).data('link');
            getWaitListModalHtmlElement();
            fillWaitListModalElement(pid, productName, productURL);
        });
    },
    addToCartModalServiceSubscription: function () {
        $(document).on('change', '.serviceSubscriptionCard .selectBillingTerm', function (e) {
            e.preventDefault();
            $('.serviceSubscriptionCard').spinner().start();
            var $serviceSubscriptionCard = $('.serviceSubscriptionCard');
            var $selectedOption = $(this).is('select') ? $(this).children('option:selected') : $(this);
            serviceSubscriptionSelect($serviceSubscriptionCard, $selectedOption);
        });
    },

    addToCartModalDefaultServiceSubscription: function () {
        $(window).on('shown.bs.modal', function () {
            var $serviceDiv = $('.serviceSubscriptionCard .selectBillingTerm');
            var $selectedOption = $serviceDiv.is('select') ? $serviceDiv.children('option:selected') : $serviceDiv;
            if ($selectedOption.val() !== '') {
                $('.serviceSubscriptionCard').spinner().start();
                var $serviceSubscriptionCard = $('.serviceSubscriptionCard');
                if ($serviceSubscriptionCard.length) {
                    serviceSubscriptionSelect($serviceSubscriptionCard, $selectedOption);
                }
            }
        });
    }
});
